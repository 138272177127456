"use client";

import Image from "next/image";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";

const Section6 = () => {
  const { t } = useTranslation();

  const reviews: Array<{
    starts: number;
    user: string;
    date: string;
    overall: string;
    text: string;
  }> = [
    {
      starts: 5,
      user: "Benjamin L.",
      date: "December 2023",
      overall: t("Home.Section6.key2"),
      text: t("Home.Section6.key3"),
    },
    {
      starts: 5,
      user: "Heritage F.",
      date: "December 2023",
      overall: t("Home.Section6.key4"),
      text: t("Home.Section6.key5"),
    },
    {
      starts: 5,
      user: "Victoria D.",
      date: "May 2023",
      overall: t("Home.Section6.key6"),
      text: t("Home.Section6.key7"),
    },
  ];

  return (
    <div className="sec-6 mx-auto max-w-6xl px-4 pb-20 md:pt-20 pt-8">
      <h2 className="mb-[28px] text-[32px] font-bold leading-9 md:text-[34px]">
        {t("Home.Section6.key1")}
      </h2>

      <div className="hidden flex-row items-center gap-[54px] md:flex">
        <div className=" flex-shrink-0">
          <div className="flex flex-col items-center gap-[26px]">
            <div className="flex flex-col items-center">
              <div className="min-h-[255px] bg-gray-100 w-full">
                {/* <Image
                alt="Avis Home Highlight"
                width="255"
                height="300"
                className="h-[300px] w-[255px] object-cover"
                src=""
              /> */}
              </div>
              <div className="flex flex-col items-center rounded-b-xl bg-white px-[52px] py-6 shadow-md shadow-[rgba(44,48,77,0.05)]">
                <div className="mb-1.5 flex items-center">
                  <span className="mr-1 text-[28px] font-bold leading-normal">
                    5
                  </span>
                  {[1, 2, 3, 4, 5].map((_, index) => (
                    <Image
                      key={index}
                      alt="icon"
                      width="28"
                      height="28"
                      src="/icons/star.svg"
                    />
                  ))}
                </div>
                <span className="text-base font-medium leading-normal">
                  {t("Home.Section6.key8")}{" "}
                  <strong>{t("Home.Section6.key9")}</strong>
                </span>
                <span className="text-sm">{t("Home.Section6.key10")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          {reviews.map((review, index) => (
            <div
              key={review.user}
              className={`flex flex-col rounded-2xl bg-white px-4 py-6 shadow-md shadow-[rgba(44,48,77,0.05)] ${
                index === 1 ? " ml-[60px]" : ""
              }`}
            >
              <div className="mb-2 flex items-center">
                {Array.from(new Array(review.starts), (item) => item).map(
                  (_, index) => (
                    <Image
                      key={index}
                      alt="icon"
                      width="24"
                      height="24"
                      src="/icons/star.svg"
                    />
                  )
                )}
              </div>
              <span className="text-base font-bold">{review.user}</span>
              <span className="mb-3.5  text-sm font-normal text-grey">
                {review.date}
              </span>
              <span className="mb-3.5 text-base font-bold leading-normal">
                {review.overall}
              </span>
              <span className="text-[15px] font-normal leading-6">
                {review.text}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Small screen carousel */}
      <div className="md:hidden opc">
        <Swiper
          pagination={{
            // dynamicBullets: true,
            clickable: true,
            bulletActiveClass:
              "!bg-primary !w-6 !rounded-[99px] !opacity-100 !transition-all",
          }}
          //   autoHeight={false}
          loop={true}
          modules={[Pagination]}
          spaceBetween={30}
          className="mySwiper"
          slidesPerView={1}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div
                className={`min-h-[300px] flex flex-col rounded-2xl bg-white px-4 py-6 shadow-md shadow-[rgba(44,48,77,0.05)] mb-14`}
              >
                <div className="mb-2 flex items-center">
                  {Array.from(new Array(review.starts), (item) => item).map(
                    (_, index) => (
                      <Image
                        key={index}
                        alt="icon"
                        width="24"
                        height="24"
                        src="/icons/star.svg"
                      />
                    )
                  )}
                </div>
                <span className="text-base font-bold">{review.user}</span>
                <span className="mb-3.5  text-sm font-normal text-grey">
                  {review.date}
                </span>
                <span className="mb-3.5 text-base font-bold leading-normal">
                  {review.overall}
                </span>
                <span className="text-[15px] font-normal leading-6">
                  {review.text}
                </span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Section6;
