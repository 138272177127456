import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section1.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section3.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section4.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section5.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section6.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section7.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section8.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(home)/components/pages/Home/Section9.tsx");
