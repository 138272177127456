"use client";

import React from "react";
import { useTranslation } from "react-i18next";

const Section2 = () => {
  const { t } = useTranslation();

  return (
    <div className="sec-2 w-full bg-[#F9FCFC]">
      <div className="mx-auto grid w-full max-w-6xl grid-cols-1 place-items-center justify-between gap-[38px] py-[60px] md:grid-cols-3 md:gap-6">
        <div className="flex flex-col items-center">
          <div className="mb-2 w-fit rounded-full theme-bg-color p-[19px] text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              fill="none"
              //   size="42"
              viewBox="0 0 42 42"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21.914 6.228V4.375M13.575 13.64h-1.853M32.105 13.64h-1.853M16.355 8.08l-1.31-1.31M27.472 8.08l1.31-1.31M16.187 21.676v14.199M25.67 21.676h3.934a3.727 3.727 0 0 1 3.64 4.529l-1.485 6.744a3.727 3.727 0 0 1-3.64 2.926"
              ></path>
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M28.12 35.875H10.968a1.864 1.864 0 0 1-1.864-1.864V23.54c0-1.03.835-1.864 1.864-1.864h5.236M25.67 21.676v-5.171c0-.942-.531-1.802-1.373-2.223l-.629-.314a3.106 3.106 0 0 0-4.142 1.342l-3.321 6.366"
              ></path>
            </svg>
          </div>
          <h3 className="text-center text-lg font-bold">
            {t("Home.Section2.key1")}
          </h3>
          <p className="text-center text-base">{t("Home.Section2.key2")}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-2 w-fit rounded-full theme-bg-color p-[19px] text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              fill="none"
              //   size="42"
              viewBox="0 0 42 42"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17.5 3.5h7M15.75 17.5 21 22.75M35 8.75l-4.095 4.095M33.25 7l3.5 3.5M5.25 26.25H14M14 31.5H8.75M14 36.75h-1.75M7.469 19.25C9.023 13.216 14.479 8.75 21 8.75c7.732 0 14 6.268 14 14s-6.268 14-14 14"
              ></path>
            </svg>
          </div>
          <h3 className="text-center text-lg font-bold">
            {t("Home.Section2.key3")}
          </h3>
          <p className="text-center text-base">{t("Home.Section2.key4")}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-2 w-fit rounded-full theme-bg-color p-[19px] text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              fill="none"
              //   size="42"
              viewBox="0 0 42 42"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5.25 21C5.25 12.3 12.3 5.25 21 5.25S36.75 12.3 36.75 21M36.75 21c0 8.7-7.05 15.75-15.75 15.75S5.25 29.7 5.25 21M14.875 15.75v1.75M27.125 15.75v1.75"
              ></path>
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M27.125 25.704S24.827 28 21 28c-3.829 0-6.125-2.296-6.125-2.296"
              ></path>
            </svg>
          </div>
          <h3 className="text-center text-lg font-bold">
            {t("Home.Section2.key5")}
          </h3>
          <p className="text-center text-base">{t("Home.Section2.key6")}</p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
