"use client";

import Link from "next/link";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const Section9 = () => {
  const { t } = useTranslation();

  return (
    <div className="py-[60px] md:py-[80px]">
      <div className="mx-auto flex max-w-6xl flex-col justify-center gap-[34px] px-4 md:gap-[40px]">
        <div
          className="relative flex flex-col md:flex-row md:gap-14"
          id=":r1s:"
        >
          <div className="flex flex-col gap-6">
            <h2 className="block font-bold mb-[2px] text-[32px] md:text-[34px]">
              {t("Home.Section9.key1")}
            </h2>
            <div className="text-justify text-base font-normal leading-6">
              <Trans i18nKey={"Home.Section9.key2"} />
            </div>
            <h2 className="block font-bold mb-[2px] text-[32px] md:text-[34px]">
              {t("Home.Section9.key3")}
            </h2>
            <div className="text-justify text-base font-normal leading-6">
              <Trans
                i18nKey={"Home.Section9.key4"}
                components={{
                  anchor: (
                    <Link
                      className="font-semibold text-secondary transition-colors hover:text-secondary/80 hover:underline"
                      href={"/health-insurance"}
                    />
                  ),
                }}
              />
            </div>
            <h2 className="block font-bold mb-[2px] text-[32px] md:text-[34px]">
              {t("Home.Section9.key5")}
            </h2>
            <div className="text-justify text-base font-normal leading-6">
              <Trans i18nKey={"Home.Section9.key6"} />
            </div>
            <h2 className="block font-bold mb-[2px] text-[32px] md:text-[34px]">
              {t("Home.Section9.key7")}
            </h2>
            <div className="text-justify text-base font-normal leading-6">
              <Trans i18nKey={"Home.Section9.key8"} />
            </div>
            <h2 className="block font-bold mb-[2px] text-[32px] md:text-[34px]">
              {t("Home.Section9.key9")}
            </h2>
            <div className="text-justify text-base font-normal leading-6">
              <Trans i18nKey={"Home.Section9.key10"} />
            </div>
            <h2 className="block font-bold mb-[2px] text-[32px] md:text-[34px]">
              {t("Home.Section9.key11")}
            </h2>
            <div className="text-justify text-base font-normal leading-6">
              <Trans i18nKey={"Home.Section9.key12"} />
            </div>
            <h2 className="block font-bold mb-[2px] text-[32px] md:text-[34px]">
              {t("Home.Section9.key13")}
            </h2>
            <div className="text-justify text-base font-normal leading-6">
              <Trans i18nKey={"Home.Section9.key14"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section9;
