"use client";

import React from "react";
import { useTranslation } from "react-i18next";

const Section3 = () => {
  const { t } = useTranslation();

  return (
    <div className="sec-3 mx-auto max-w-6xl px-4 py-[60px]">
      <div className="grid grid-cols-12 gap-y-14 md:gap-x-8">
        <div className="md:col-span-5 col-span-12 md:order-1 order-2">
          <h2 className="mb-[14px] text-[32px] font-bold leading-9">
            {t("Home.Section3.key1")}
          </h2>

          <p className="mb-6 text-base">
            {t("Home.Section3.key2")}
            <span className="font-semibold">{t("Home.Section3.key3")}</span>,
            {t("Home.Section3.key4")}
            <span className="font-semibold">{t("Home.Section3.key5")}</span>
          </p>

          <div className="flex flex-col gap-[22px]">
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-full bg-primary text-white">
                <span className="">1</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key6")}
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-full bg-primary text-white">
                <span className="">2</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key7")}
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-full bg-primary text-white">
                <span className="">3</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key8")}
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-full bg-primary text-white">
                <span className="">4</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key9")}
              </span>
            </div>
          </div>
        </div>
        <div className="md:col-span-7 col-span-12 relative md:order-2 order-1">
          {/* Character */}
          <div className="min-h-[255px] w-[200px] bg-sky-200 absolute md:block hidden left-0 bottom-0 -translate-x-14 translate-y-14" />

          <div className="min-h-[380px] bg-gray-100">
            {/* <video
            className="mb-[60px] h-auto w-full flex-shrink-0 rounded-lg object-contain md:mb-0 md:h-[380px] md:w-[664px] md:max-w-[60%] md:object-contain"
            src=""
            autoPlay
            loop
            muted
            playsInline
          ></video> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
