"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";

const Section5 = () => {
  const { t } = useTranslation();

  const services: Array<{
    href: string;
    icons: React.ReactNode;
    title: string;
  }> = [
    {
      href: "/services/assurance-voiture",
      icons: (
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            // size="42"
            viewBox="0 0 42 42"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M30.5 31.5c0 2.9-2.35 5.25-5.25 5.25h-7A5.25 5.25 0 0 1 13 31.5M13 31.5v-7M30.5 24.5v7M34 19.25l-3.5 5.25M9.5 19.25 13 24.5M26.125 24.5a11.375 11.375 0 0 1-8.75 0M30.5 15.75V9.43a3.527 3.527 0 0 0-2.605-3.393 24.382 24.382 0 0 0-12.29 0A3.527 3.527 0 0 0 13 9.43v6.32M17.375 14h8.75"
            ></path>
          </svg>
        </div>
      ),
      title: t("Home.Section1.key4"),
    },
    {
      href: "/services/assurance-sante",
      icons: (
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            // size="42"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21.25 8.619c1.356-1.587 3.703-3.369 7.276-3.369 6.25 0 10.443 5.866 10.443 11.33 0 11.42-14.219 20.17-17.719 20.17-3.5 0-17.719-8.75-17.719-20.17 0-5.464 4.193-11.33 10.442-11.33 3.574 0 5.92 1.782 7.277 3.369Z"
              clipRule="evenodd"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12.5 20.125h3.889l2.917-4.375 3.888 8.75 2.918-4.375H30"
            ></path>
          </svg>
        </div>
      ),
      title: t("Home.Section1.key5"),
    },
    {
      href: "/services/protection-juridique",
      icons: (
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            viewBox="0 0 42 42"
            // size="42"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14.747 36.757H9.495a3.501 3.501 0 0 1-3.502-3.502V8.745a3.501 3.501 0 0 1 3.502-3.502h19.258a3.501 3.501 0 0 1 3.501 3.502v5.252M30.103 26.532l6.446 5.456A2.705 2.705 0 1 1 32.8 35.87l-5.715-6.293M12.996 13.997h10.505M12.996 21h3.502"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m23.368 24.258-2.565 2.043a2.138 2.138 0 0 0-.175 3.178l1.952 1.96a2.113 2.113 0 0 0 3.151-.175l2.059-2.56c.49-.61 1.045-1.162 1.656-1.648l2.565-2.045a2.137 2.137 0 0 0 .175-3.176l-1.945-1.963a2.113 2.113 0 0 0-3.151.176l-2.066 2.563c-.49.608-1.046 1.16-1.656 1.647Z"
              clipRule="evenodd"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M25.117 36.721h-3.502"
            ></path>
          </svg>
        </div>
      ),
      title: t("Home.Section1.key6"),
    },
    {
      href: "/services/prevoyance",
      icons: (
        <div className="flex-shrink-0">
          <svg
            width="42"
            height="42"
            viewBox="0 0 43 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // size="42"
          >
            <path
              d="M21.25 5.25V7"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M14.25 31.8208V33.5708V33.5883C14.25 35.5115 15.8162 37.0708 17.75 37.0708C19.6837 37.0708 21.25 35.5115 21.25 33.5883V20.6103"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 22.708C18.8997 19.908 23.6003 19.908 26.5 22.708C29.3997 19.908 34.1003 19.908 37 22.708C36.9772 14.0297 29.9352 7 21.25 7C12.5648 7 5.52275 14.0297 5.5 22.708C8.39975 19.908 13.1003 19.908 16 22.708Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      ),
      title: t("Home.Section1.key7"),
    },
    {
      href: "/services/assurance-menage",
      icons: (
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            // size="42"
            viewBox="0 0 42 42"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M35.255 16.82V6.993c0-.966-.784-1.75-1.75-1.75h-3.252c-.966 0-1.75.784-1.75 1.75v4.037"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5.993 18.749 19.471 7.197a3.5 3.5 0 0 1 4.557 0L37.507 18.75"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8.245 16.82v16.435a3.5 3.5 0 0 0 3.501 3.502H20M31.379 38.507a7.879 7.879 0 1 1 .001-15.758 7.879 7.879 0 0 1-.001 15.758Z"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m34.492 29.073-3.89 3.89-2.336-2.334"
            ></path>
          </svg>
        </div>
      ),
      title: t("Home.Section1.key8"),
    },
    {
      href: "/services/hypotheque",
      icons: (
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            // size="42"
            viewBox="0 0 42 42"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M37.603 16.11c.504 1.582.764 3.23.772 4.89 0 9.182-7.443 16.625-16.625 16.625S5.125 30.182 5.125 21 12.568 4.375 21.75 4.375c1.66.007 3.308.268 4.89.772M38.375 6.9l-3.5 3.5M34.875 10.4l-2.188-2.187"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14.75 24.5v-5.25c0-.797.363-1.552.985-2.05l4.375-3.5a2.625 2.625 0 0 1 3.28 0l4.375 3.5c.623.498.985 1.253.985 2.05v5.25a2.625 2.625 0 0 1-2.625 2.625h-8.75A2.625 2.625 0 0 1 14.75 24.5Z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      ),
      title: t("Home.Section1.key9"),
    },
  ];

  return (
    <div
      className="sec-5 relative overflow-hidden pb-14 pt-14 md:pb-14"
      id="services-area"
    >
      <div className="absolute -top-1/2 -z-10 h-full w-full rounded-full bg-primary opacity-30 blur-[600px]"></div>
      <div className="mx-auto flex max-w-6xl flex-col justify-center px-4">
        <h2 className="text-[32px] font-bold leading-9 md:text-[34px]">
          {t("Home.Section5.key1")}
        </h2>
        <p className="mb-7 text-base leading-6">{t("Home.Section5.key2")}</p>
        <div className="mb-[28px] grid grid-cols-[repeat(auto-fill,minmax(160px,1fr))] gap-[14px]">
          {services.map((service) => (
            <Link
              key={service.href}
              rel="next"
              className="card-shadow flex h-[136px] w-[160px] flex-col items-center justify-center gap-2.5 rounded-xl bg-white transition-all hover:scale-105 hover:bg-neutral-50 hover:shadow-xl"
              href={service.href}
            >
              {service.icons}
              <span className="text-center text-[14px] font-medium">
                {service.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
      <div className="md:hidden">
        <div className="flex flex-col items-center gap-[26px]">
          <div className="flex flex-col items-center">
            <div className="min-h-[255px] bg-gray-100 w-full">
              {/* <Image
              alt="Avis Home Highlight"
              width="255"
              height="300"
              className="h-[300px] w-[255px] object-cover"
              src=""
            /> */}
            </div>
            <div className="flex flex-col items-center rounded-b-xl bg-white px-[52px] py-6 shadow-md shadow-[rgba(44,48,77,0.05)]">
              <div className="mb-1.5 flex items-center">
                <span className="mr-1 text-[28px] font-bold leading-normal">
                  5
                </span>
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <Image
                    key={index}
                    alt="icon"
                    width="28"
                    height="28"
                    src="/icons/star.svg"
                  />
                ))}
              </div>
              <span className="text-base font-medium leading-normal">
                {t("Home.Section5.key3")}{" "}
                <strong>{t("Home.Section5.key4")}</strong>
              </span>
              <span className="text-sm">{t("Home.Section5.key5")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
