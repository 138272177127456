"use client";

import Image from "next/image";
import React from "react";
import { useTranslation } from "react-i18next";

const Section4 = () => {
  const { t } = useTranslation();

  return (
    <div className="my-[60px] flex flex-col items-center justify-center md:my-[80px]">
      <div>
        <h2 className="mb-1 text-center text-3xl font-bold md:text-[34px]">
          {t("Home.Section4.key1")}
        </h2>
        <p className="text-center text-base font-medium leading-normal text-grey md:text-lg">
          {t("Home.Section4.key2")}
        </p>
      </div>
      <div className="flex max-w-screen-lg flex-nowrap items-center justify-between gap-6 overflow-x-auto px-3.5 py-[40px] md:w-full">
        <Image
          alt="partnaire"
          loading="lazy"
          width="162"
          height="42"
          decoding="async"
          data-nimg="1"
          className="h-[36px] w-[140px] flex-shrink-0 opacity-50 md:h-[42px] md:w-[162px]"
          src="/images/allianz_grey.svg"
        />
        <Image
          alt="partnaire"
          loading="lazy"
          width="162"
          height="42"
          decoding="async"
          data-nimg="1"
          className="h-[36px] w-[140px] flex-shrink-0 opacity-50 md:h-[42px] md:w-[162px]"
          src="/images/baloise_grey.svg"
        />
        <Image
          alt="partnaire"
          loading="lazy"
          width="162"
          height="42"
          decoding="async"
          data-nimg="1"
          className="h-[36px] w-[140px] flex-shrink-0 opacity-50 md:h-[42px] md:w-[162px]"
          src="/images/vaudoise_grey.svg"
        />
        <Image
          alt="partnaire"
          loading="lazy"
          width="162"
          height="42"
          decoding="async"
          data-nimg="1"
          className="h-[36px] w-[140px] flex-shrink-0 opacity-50 md:h-[42px] md:w-[162px]"
          src="/images/zurich_grey.svg"
        />
        <Image
          alt="partnaire"
          loading="lazy"
          width="162"
          height="42"
          decoding="async"
          data-nimg="1"
          className="h-[36px] w-[140px] flex-shrink-0 opacity-50 md:h-[42px] md:w-[162px]"
          src="/images/helvetia_grey.svg"
        />
      </div>
    </div>
  );
};

export default Section4;
